.btn{
    font-size: 16px;
    height: 42px;
}

.not-filled-btn{
    border: 2px solid #5746EC;
    color: #5746EC;
    border-radius: 7px;
    position:relative;
    z-index: 0;
    &:before{
        content: '';
        width: 0;
        transition: width 0.3s ease-in;
        background-color: #5746ec;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        height: 100%;
    }
    &:hover{
        color: #fff;
        &:before{
            width: 100%;
            transition: width 0.3s ease-in;
        }
    }
}

.width120{
    width: 120px;
}

.filled-btn{
    border: 2px solid #5746EC;
    color: #fff;
    background-color: #5746EC;
    border-radius: 7px;
    &:hover{
        color: #fff;
    }
}

.width250{
    width: 250px;
}