// Libraries
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');
body{
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    overflow-x: hidden;
}

// Predefined classes
@import './mixins.scss';
@import './variable.scss';
@import './predefinedClassess.scss';

// components
@import './components/button.scss';
@import './components/popup.scss';
@import './components/footer.scss';
@import './components/forms.scss';
@import './components/header.scss';
@import './components/customAntDesing.scss';
@import './components/tables.scss';

// theme
@import './theme/theme.scss';
@import './theme/utils.scss';
@import './theme/icons.scss';

// Pages
@import './pages/index.scss';
@import './pages/login.scss';
@import './pages/masterSetup.scss';
@import './pages/productManagement.scss';
@import './pages/underWritter.scss';
@import './pages/users.scss';


