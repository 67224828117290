.form-group{
    margin-bottom: 6px;
    label{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
    }

    input.form-control{
        width: 100%;
        height: 42px;
    }

    textarea.form-control{
        height: 300px;
        width: 100%;
    }
}