footer{
    padding: 50px 0 0 0;
    margin-top: 100px;
    h4{
        color: #012333;
        font-size: 18px;
        opacity: 0.7;
    }

    ul{
        padding: 0;
        list-style-type: none;
        margin-top: 25px;
        li{
            margin: 10px 0;
            .footNav{
                color: #5C5F62;
                font-size: 14px;
                text-decoration: none;
                &:hover{
                    color: #5746ec;
                }
            }
        }
    }

    .bottomFooter{
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 70px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        color: #5C5F62;
        font-weight: 300;
    }
}