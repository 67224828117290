p{
    font-size: 16px;
    font-weight: 400;
}

a{
    text-decoration: none;
}

h1{
    @include responsivemax(991){
        font-size: calc(0.9rem + 1.5vw);
    }
}

.topSection{
    .content-section{
        height: calc(100vh - 78px);
        .anticon-right{
            position: relative;
            top: -2px;
        }
    }

    .mobi{
        @include responsivemax(768){
            text-align: center;
        }

        button{
            @include responsivemax(768){
                margin-right: 0 !important;
                margin-bottom:10px;
            }
        }
    }

    .sub-heading{
        font-size: 2rem;
        color: #ffff;
        @include responsivemax(1280){
            font-size: 1.8rem;
        }
        
        @include responsivemax(991){
            font-size: 1.5rem;
        }
        
        @include responsivemax(768){
            font-size: 1.2rem;
        }
    }
}

.height55{
    height: 55px !important;

    @include responsivemax(768){
        height: 44px !important;
    }
}

.colord-heading{
    background: linear-gradient(92.84deg, #5746EC -34.59%, #F55656 93.19%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.clientSection{
    padding: 50px 0px;
    background: #F8F8F8;
    
    .content-fit{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

.expertCloudSection{

    .subH{
        font-size: 26px;
        font-weight: 200;
        @include responsivemax(991){
            font-size: 22px;
        }
    }
    .expertSticky{
        top: 110px;
        margin-bottom: 132px;
        position: sticky;
        .expert_Cloud_bg{
            max-width: 600px;
            width: 100%;
        }

        .assetImg{
            position: absolute;
            width: 70%;
            bottom: 55px;
            border-radius: 10px;
            box-shadow: rgb(0 0 0 / 10%) 0px 1px 145px;
            opacity: 0;
            visibility: hidden;
            animation-name: slidetotop;
            animation-duration: .5s;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
            left: 0;
            right: 50px;
            margin: auto;

            @include responsivemax(1400){
                right: 0;
            }
        }

        .assetImg.active{
            animation-name: slidefrombottom;
            opacity: 1;
            visibility: visible;
        }
    }

    .inner-heading{
        background: linear-gradient(92.84deg, #5746EC -34.59%, #F55656 93.19%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .anticon-right{
        position: relative;
        top: -2px;
    }

    .contentBox{
        margin-left: 50px;
        @include responsivemax(768){
            margin-left: 0;
        }
    }

    .searchUL{
        padding: 0;
        margin-bottom: 0;
        list-style-type: none;
        li{
            border: 1px solid #5746ec;
            padding: 12px 30px;
            font-size: 14px;
            display: inline-block;
            border-radius: 5px;
            margin-right: 15px;
            margin-bottom: 15px;

            @include responsivemax(991){
                padding: 10px 20px;
            }
            
            @include responsivemax(768){
                padding: 10px 15px;
                font-size: 12px;
            }
        }
    }

    .appendDot{
        padding-left: 30px;
        position: relative;
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 1rem !important;
        @include responsivemax(991){
            font-size: 1.1rem;
        }
        @include responsivemax(768){
            font-size: 1rem;
        }
        &:before{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #5746ec;
            border-radius: 50%;
            left:0;
            top: 9px;
        }
    }

    .mobile{
        position:relative;
        .imgS{
            position:relative;
            .expert_Cloud_bg{
                width: 100%;
                margin: auto;
                max-width: 500px;
            }
            .assetImg{
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 380px;
                top: 50%;
                transform: translate(0px, -50%);
            }
        }
    }
}

@keyframes slidetotop{
    0%{
        top: 0px;
        opacity: 0.9;
        visibility: visible;
    }
    25%{
        top: -265px;
        opacity: 0.6;
        visibility: visible;
    }
    50%{
        top: -530px;
        opacity: 0.3;
        visibility: visible;
    }
    
    75%{
        top: -796px;
        opacity: 0;
        visibility: hidden;
    }

    100%{
        bottom: -796;
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes slidefrombottom{
    0%{
        bottom: -796px;
        opacity: 0;
    }
    50%{
        bottom: -398px;
        opacity: 0.5;
    }
    100%{
        bottom: 55px;
        opacity: 1;
    }
}

.blue-colored{
    color: #5746EC;
}

.relationship{
    .mob{
        @include responsivemax(768){
            text-align: center;
            margin-top: 100px;
        }
    }
    .fs-35{
        @include responsivemax(1400){
            font-size: 30px;
        }
        
        @include responsivemax(1200){
            font-size: 22px;
        }
        
        @include responsivemax(768){
            font-size: 1.1rem;
        }
    }

    .pos-relative{
        .mt-100{
            @include responsivemax(1200){
                margin-top: 70px;
            }
            
            @include responsivemax(768){
                margin-top: 50px;
            }
            
        }
    }

    .relationshipOpportunity{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate(0px, -50%);

        h1{
            color: #fff;
        }

        a{
            margin-top: 50px;
            display: inline-block;
            button{
                color: #fff;
                background: transparent;
                box-shadow: none;
                outline: none;
                font-size: 20px;
                border: none;
                .anticon{
                    position: relative;
                    top: -5px;
                }
            }
        }
    }

    .relationShipsDiv{
        border-radius: 35px;
        overflow: hidden;
        background-color: #edf2f7;
        .img{
            width:50%;
            align-self: stretch;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .contentS{
            width: 50%;
            padding:3rem;
            li{
                font-size: 2rem;
                margin-bottom: 20px;
                font-weight: 200;
            }
        }
    }

    .getOnBoard{
        position: absolute;
        bottom: 0; 
        width: 96%;
        @include responsivemax(768){
            position: relative;
            bottom: unset;
            width: 100%;
            margin-top: 40px;
        }
    }
}

.newsInsights{
    .newsInsightsContent{
        h5{
            margin-top: 40px;
            font-size: 24px;
            margin-bottom: 9px;
            font-weight: 300;
            @include responsivemax(1200){
                font-size: 20px;
            }
        }

        p{
            font-size: 15px;
            margin-bottom: 22px;
            margin-top: 30px;
            @include responsivemax(1200){
                font-size: 14px;
            }
        }

        .findOutMore{
            color: #5746EC;
            span{
                position: relative;
                display: inline-block;
                padding-bottom: 5px;
                &::before{
                    content: '';
                    height: 2px;
                    background-color: #5746EC;
                    width: 25%;
                    transition: width 0.5s ease;
                    position: absolute;
                    bottom: 0px;
                }
            }
            
            &:hover{
                span{
                    &::before{
                        width: 100%;
                    }
                }
            }
        }

        .d-flex{
            div{
                background-color: #e7e7e7;
                color: #5c5f62;
                padding: 5px 10px;
                margin-right: 5px;
                border-radius: 5px;
                font-weight: 500;
                margin-bottom: 20px;
            }
        }

        .timeStamp{
            color: #5C5F62;
            font-weight: 300;
        }
    }
}

.nextynBg{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    text-align: center;
    h1{
        color: #fff;
    }

    .getInTouch{
        width: 250px;
        background-color: #fff;
        text-align: center;
        margin: auto;
    }
}

.faqs{
    .mainHead{

    }

    .question{
        color: #5746ec;
        font-weight: 400;
    }

    .answers{

    }
}

.singleBlog{
    .singleLink{
        text-decoration: none;
        .anticon{
            margin-right: 10px;
            position: relative;
            top: -4px;
        }
        &:hover{
            h4{
                color: #5746ec;
            }
        }
    }
    .article_tags{
        background-color: #e7e7e7;
        color: #5c5f62;
        padding: 5px 10px;
        margin-right: 5px;
        border-radius: 5px;
        font-weight: 500;
    }

    .timeStamp{
        color: #5C5F62;
        font-weight: 300;
        margin-left: 20px;
    }
}

.contactUs{
    .formBox{
        box-shadow: 0px 5px 100px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 30px;
        margin: auto
    }

    img{
        min-height: 250px;
    }

    h1.mb-3, h1.mb-5{
        font-weight: 200;
        font-size: 3rem;
        @include responsivemax(1200){
            font-size: 2.2rem;
        }
        
        @include responsivemax(991){
            font-size: 2rem;
        }
        
        @include responsivemax(768){
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 15px !important;
            line-height: 25px;
        }
    }

    .social-logos{
        list-style-type: none;
        display: inline-block;
        padding-top: 30px;
        li{
            display: inline-block;
            margin-right: 10px;
            a{
                &:hover{
                    svg{
                        path{
                            fill: #5746ec;
                        }
                    }
                }
            }
        }
    }
}

.text-large{
    font-size: 4rem;
    @include responsivemax(1280){
        font-size: 3.5rem;
    }
    @include responsivemax(991){
        font-size: 3rem;
    }
    
    @include responsivemax(768){
        font-size: 2rem;
    }
}

.medium-small{
    font-size: 1.2rem;
    font-weight: 300;
    @include responsivemax(991){
        font-size: 1.1rem;
    }
    
    @include responsivemax(991){
        font-size: 1rem;
    }
}

.blogUl{
    li{
        font-size: 16px;
        margin-bottom: 5px;
    }
}


.mb-300{
    margin-bottom: 300px;
}


