.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.colord-heading2 {
  background: linear-gradient(
    92.84deg,
    #5746ec -34.59%,
    #f55656 93.19%
  ) !important;
  background-clip: text;
}

.mobi h1 {
  color: #fff;
  font-size: 38px !important;
  font-weight: 300 !important;
}

.mobi label {
  color: #fff;
  text-align: left;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 0 11px;
}

.range .content-fit {
  font-size: 2rem;
  font-weight: 400;
}

.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: none !important;
  border-radius: 2px 2px 0 0;
}

.site-card-border-less-wrapper span {
  color: #8c8c8c;
}

.expert-rate .indus {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: #8c8c8c;
}

.expert-rate .indus-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  color: #a5a5a5;
}

.comon-divs-re {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 30px;
}
.comon-divs-re h5 {
  font-weight: 300;
  margin-bottom: 10px;
}
.new-reguest {
  background: #fff !important;
  color: #5746ec !important;
  width: 70%;
  border: solid 1.4px #5746ec !important;
  /* font-size: 16px !important; */
  font-weight: 500 !important;
  height: 40px;
  text-align: center;
  transition: all 0.5s;
  line-height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-reguest:hover {
  background: #5746ec !important;
  color: #fff !important;
}
.right-divs-2 {
  display: inline-block;
  width: 100%;
  position: relative;
}
.sp-hr {
  width: 90%;
  margin-left: 55px;
}

.findExperts .find_companyName {
  font-size: 19px;
  width: 100%;
  font-weight: 500;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.noResultFound {
  max-width: 700px;
  margin: auto;
}

.findExperts.comon-divs-re .linkH5 {
  width: 100%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comon-divs-re h5 {
  font-weight: 300;
  margin-bottom: 10px;
}

.linkH5 {
  cursor: pointer;
  font-size: 16px !important;
  line-height: 22px;
}

.findExperts .next-list li {
  margin-right: 6px;
}

.next-list li {
  border: solid 1.2px #d9d9d9;
  border-radius: 7px;
  background: #fff;
  padding: 5px 12px;
  display: inline-block;
  font-size: 13px;
  margin-right: 13px;
  font-weight: 500;
  /* cursor: pointer; */
  transition: all 0.5s;
  color: #5c5f62;
}

.next-list li {
  margin-bottom: 10px;
}

.tp-list1 li .las,
.next-list .las {
  font-size: 24px;
  color: #5746ec;
}

.la,
.las {
  font-family: "Line Awesome Free";
  font-weight: 900;
}

.findExperts .expertRate {
  font-size: 24px;
}

.right-divs-2 h5 {
  font-weight: 500;
  color: #000;
}
.comon-divs-re h5 {
  font-weight: 300;
  margin-bottom: 10px;
}

.right-divs-2:after {
  position: absolute;
  content: "";
  left: -21px;
  width: 1px;
  height: 150px !important;
  background: #e7e7e7;
  top: -32px;
}

.findExperts.comon-divs-re {
  width: 100%;
  padding-right: 20px;
}

.comon-divs-re {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  padding-left: 20px;
  margin-bottom: 30px;
  width: 95%;
}

.clientSection2 {
  padding: 50px 0px;
  background: #f8f8f8;
}

.ant-slider-track {
  position: absolute;
  height: 6px !important;
  background-color: #91d5ff;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.findExperts .expertRate {
  font-size: 24px;
}

.right-divs-2 h5 {
  font-weight: 500;
  color: #000;
}

.comon-divs-re h5 {
  font-weight: 300;
  margin-bottom: 10px;
}

.ant-slider-disabled .ant-slider-track {
  background-color: #91d5ff !important;
}

.range .content-fit {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 4rem !important;
}

.ant-tooltip {
  z-index: 0 !important;
}

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: #1890ff !important;
  box-shadow: none;
  cursor: not-allowed;
}

.trendsBtnA {
  width: 100%;
}

.trendsBtn .anticon {
  position: absolute !important;
  right: 14px;
  top: 50%;
  transform: translate(0px, -50%);
}

.ant-dropdown-menu {
  overflow: scroll !important;
  max-height: 200px !important;
}

.trendsBtn {
  width: 100%;
  text-align: left;
  height: 40px;
  font-weight: 500;
  border: 1px solid #DFDFDF;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 7%);
  border-radius: 6px;
}

@media screen and (min-width: 100px) and (max-width: 480px) {
  .sub-heading span {
    display: inline-block;
    font-weight: 400;
    font-size: 15px !important;
  }
  .mobi h1 {
    font-size: 18px !important;
  }
  .range .content-fit {
    font-weight: 400;
    font-size: 20px;
    text-align: left !important;
  }
  .findExperts.comon-divs-re {
    width: 100%;
    padding-right: 20px;
    /* padding-top: 5px; */
    padding-bottom: 30px;
  }

  .findExperts .find_companyName {
    font-size: 19px;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 30px;
  }

  .ant-slider {
    width: 22rem !important;
  }
  .selection-optn .label {
    text-align: center;
  }
  .colord-heading2 img {
    max-width: 50% !important;
  }
}

@media (max-width: 576px) {
  .sub-heading span {
    display: inline-block;
    font-weight: 400;
    font-size: 18px !important;
  }
  .mobi h1 {
    font-size: 20px !important;
  }
  .ant-slider {
    width: 22rem !important;
  }
  .range .content-fit {
    font-weight: 400;
    font-size: 28px !important;
    text-align: left !important;
  }
  .findExperts.comon-divs-re {
    width: 100%;
    padding-right: 20px;
    /* padding-top: 5px; */
    padding-bottom: 30px;
  }

  .findExperts .find_companyName {
    font-size: 19px;
    width: 100%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 30px;
  }
  .selection-optn .label {
    text-align: center;
  }
  .colord-heading2 img {
    max-width: 50% !important;
  }
}
