.ant-tabs-tab{
    padding: 6px 25px;
    border-radius: 5px;
    margin: 0 !important;
    .ant-tabs-tab-btn{
        font-size: 16px;
    }
    &:hover{
        .ant-tabs-tab-btn{
            color: #5746ec;
        }
    }
}
.ant-tabs-tab-active{
    background: #5746ec;
    .ant-tabs-tab-btn{
        color: #fff !important;
    }
}

.ant-tabs-ink-bar{
    background: #5746ec;
}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    padding: 10px 15px;
}
.ant-dropdown-menu-title-content > a{
    font-size: 16px;
}